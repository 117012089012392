import { AppointmentComponentContentProps } from '../../pages/schedule/schedulePage.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import React from 'react';
import { classes } from './.classes';
import { SpecialistResource } from '../../services/specialists/specialists.types';
import { Service } from '../../services/services/services.types';
import { DateTime } from 'luxon';

export type TwoDates = {
  startDate: string,
  endDate: string,
};

const showTime = ({ startDate, endDate }: TwoDates) => {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  return start.toLocaleString(DateTime.TIME_24_SIMPLE) + ' - ' + end.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const AppointmentComponentContent = ({ data, studioId }: AppointmentComponentContentProps & { isMatrix: boolean, studioId: number }) =>  {
  const { services, specialists } = useSelector((state: RootState) => state.dictionaries);

  const specialist: SpecialistResource | undefined = (specialists[studioId] || []).find((specialist: SpecialistResource) => specialist.id === data.specialistId);
  const service: Service | undefined = (services || []).find((service: Service) => service.id === data.serviceId);

  // in case of drag
  if (data.startDate instanceof Date) {
    data.startDate = DateTime.fromJSDate(data.startDate as Date).toISO() as string;
  }
  if (data.endDate instanceof Date) {
    data.endDate = DateTime.fromJSDate(data.endDate as Date).toISO() as string;
  }

  return (
      <div className={classes.container}>
        <div className={classes.text} style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div style={{}}>{showTime(data as TwoDates)}</div>
        </div>
        <div className={classes.text + ' ' + classes.content}>
          {specialist?.name}
        </div>
        <div className={classes.text + ' ' + classes.content}>
          {service?.name}
        </div>
      </div>
  );
};
