import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
  closeWindowAppointmentInfo,
  closeWindowDeleteAppointment,
  openWindowDeleteAppointment,
} from '../schedulePage.slice';
import React from 'react';
import { openDialogBookingAdd } from '../../booking/bookingAddDialog.slice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomNoRowsOverlay from '../../../components/grid/CustomNoRowsOverlay';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogDelete } from '../../../components/DialogDelete';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import bookingsService from '../../../services/bookings/bookings.service';

export const ShowAppointment = ({ deleteCallback }: { deleteCallback: (scheduleId: string) => Promise<void> }) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const {
    open,
    data,
    scheduleId,
    windows: {
      delete: {
        open: deleteAppointmentWindowOpen,
        deletingId,
      }
    }
  } = useSelector((state: RootState) => state.schedule.windows.showAppointmentInfo);

  const columns: GridColDef[] = [{
    field: 'participantId',
    headerName: 'Client Id',
    sortable: false,
    flex: 1,
    width: 200,
  }, {
    field: 'phoneNumber',
    headerName: 'Phone',
    sortable: false,
    flex: 1,
    width: 200
  }, {
    field: 'email',
    headerName: 'Email',
    sortable: false,
    flex: 1,
    width: 200
  }, {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    width: 200
  }, {
    field: 'actions',
    headerName: 'Delete',
    width: 70,
    sortable: false,
    renderCell: (params) => (
      <IconButton onClick={() => dispatch(openWindowDeleteAppointment(params.row.id))}>
        <DeleteIcon />
      </IconButton>
    ),
  }];

  const dialogClose = () => dispatch(closeWindowAppointmentInfo({}));

  const handleConfirmDelete = async () => {
    if (deletingId) {
      try {
        await bookingsService.delete(deletingId);
        dispatch(closeWindowDeleteAppointment({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Appointment successfully deleted',
        }));
        await deleteCallback(scheduleId);
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete appointment: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  }

  return (
    <Dialog open={open} onClose={dialogClose} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",
        },
      },
    }}>
      <DialogTitle>View appointment</DialogTitle>
      <DialogContent>
        <Button
            variant="contained"
            sx={{marginBottom: 1}}
            onClick={() => dispatch(openDialogBookingAdd({}))}
            startIcon={<AddCircleIcon/>}
        >Add booking</Button>
        <div style={{ height: '400px', width: '100%'}}>
          <DataGrid
            slots={{
              noRowsOverlay: () => <CustomNoRowsOverlay text={"No appointments"} />
            }}
            columns={columns}
            hideFooter={true}
            rows={data}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />

          <DialogDelete
              open={deleteAppointmentWindowOpen}
              question="Are you sure you want to delete this appointment?"
              onClose={() => dispatch(closeWindowDeleteAppointment({}))}
              onSubmit={handleConfirmDelete}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

