import { Autocomplete, Box, Button, IconButton, TextField } from '@mui/material';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import PrintIcon from '@mui/icons-material/Print';
import { RootState } from '../../store/store';
import React, { useEffect } from 'react';
import { getCompany, setInputCurrency, setInputDescription, setInputName } from './company.slice';
import { fetchCurrencies } from '../../store/dictionaries.slice';
import { setQrCodeForPrint, setShowQrCode } from '../studios/studiosPage.slice';
import CompanyService from '../../services/company/company.service';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import validateForm from '../../utils/validation/validateForm';

const CompanyPage = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { name, description, currency, code } = useSelector((state: RootState) => state.company);
  const { currencies } = useSelector((state: RootState) => state.dictionaries);

  useEffect(() => {
    (async () => {
      await dispatch(fetchCurrencies());
      await dispatch(getCompany());
    })();
  },[dispatch]);

  const saveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;

    try {
      await CompanyService.update({
        name,
        description,
      });

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Company info saved',
      }));
    } catch (e: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while update company: ' + e.message,
      }));
    }
  };

  const handlePrintQrCodeButtonClick = (code: string) => {
    dispatch(setQrCodeForPrint(code));
    dispatch(setShowQrCode(true));
  };

  return (
    <Box width={600}>
      <form onSubmit={saveChanges}>
        <Box sx={{
          margin: 2
        }}>
          Code: {code}
          <IconButton onClick={() => handlePrintQrCodeButtonClick(code)}>
            <PrintIcon/>
          </IconButton>
        </Box>
        <TextField
          label="Company name"
          variant="outlined"
          fullWidth
          value={name}
          required={true}
          onChange={(e) => dispatch(setInputName(e.target.value))}
          sx={{marginBottom: 1, marginTop: 1}}
        />

        <TextField
          rows={6}
          multiline={true}
          label="Company description"
          variant="outlined"
          fullWidth
          value={description}
          required={true}
          onChange={(e) => dispatch(setInputDescription(e.target.value))}
          sx={{marginBottom: 1, marginTop: 1}}
        />

        <Autocomplete
          options={currencies.map((currency) => ({
            id: currency,
            label: currency,
          }))}
          fullWidth
          disableClearable={true}
          disabled={true}
          inputValue={currency}
          onChange={(_, newInputValue) => dispatch(setInputCurrency(newInputValue?.label || ''))}
          sx={{marginBottom: 1, marginTop: 1}}
          renderInput={(params) => <TextField
            {...params}
            label="Currency"
            required={true}
            variant="outlined"
          />}
        />

        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 1 }}>Save Changes</Button>
      </form>
    </Box>
  );
};

export default CompanyPage;
