import { Box, Button, IconButton, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { CheckClientResponse, Client } from '../../services/clients/clients.types';
import {
  closeDialogCheck,
  fetchClients,
  Filter,
  openClientCheck,
  openDialogAddToStudio, openDialogClientCreate,
  setEmailDialogAddToStudio, setEmailDialogCreate,
  setPage, setSearchField
} from './clientsPage.slice';
import SearchIcon from '@mui/icons-material/Search';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { setBackPage, setClientId } from '../clientCard/clientCardPage.slice';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from '../applicationPage.slice';
import { InitialValues } from '../../types/types';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { ClientCheckDialog } from './components/ClientCheckDialog';
import { openError, toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { ClientAddToStudioDialog } from './components/ClientAddToStudioDialog';
import { ClientCreateDialog } from './components/ClientCreateDialog';
import { isNumeric } from '../../utils/isNumeric';

const ClientsPage = () => {
  const dispatch: ThunkDispatch<Client[], any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const { data, rowCount, paginationModel, searchField } = useSelector((state: RootState) => state.clients);
  const { email: emailForCheck } = useSelector((state: RootState) => state.clients.dialogs.check);
  const { studioId } = useSelector((state: RootState) => state.app);

  const openClientCard = (clientId: number) => {
    dispatch(setClientId(clientId));
    dispatch(setCurrentPage('client-card'));
    dispatch(setBackPage('clients'));
    navigate('#client-card');
  };

  const columns: GridColDef[] = [{
    field: 'view',
    headerName: 'View',
    width: 70,
    sortable: false,
    renderCell: (params) => (
      <IconButton onClick={() => openClientCard(params.row.id)}>
        <AccountBoxIcon />
      </IconButton>
    ),
  },{
    field: 'id',
    headerName: 'ID',
    sortable: false,
    width: 70,
  }, {
    field: 'email',
    headerName: 'E-mail',
    sortable: false,
    flex: 1,
  }, {
    field: 'phoneNumber',
    headerName: 'Phone',
    sortable: false,
    flex: 1,
  }, {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    width: 200
  }, {
    field: 'surname',
    headerName: 'Surname',
    sortable: false,
    width: 200
  }];

  const loadClients = () => {
    if (!studioId) { dispatch(openError(`Studio not found`)); return; }

    let filter: Filter | undefined;
    if (searchField.length > 0) {
      filter = isNumeric(searchField)
        ? [{ id: Number(searchField) }]
        : [{ email: searchField }, { phoneNumber: searchField }];
    }

    dispatch(fetchClients({ studioId, filter }));
  };

  useEffect(() => {
    if (studioId !== InitialValues.EMPTY) {
      loadClients();
    }
  }, [dispatch, studioId, paginationModel]);

  const handlePageChange = (props: GridPaginationModel) => {
    dispatch(setPage(props.page));
  };

  const filterOnClick = () => {
    if (studioId !== InitialValues.EMPTY) {
      loadClients();
    }
  };

  const addClient = () => {
    dispatch(openClientCheck({}));
  };

  const clientCheckCallBack = (result: CheckClientResponse): void => {
    if (!(result in CheckClientResponse)) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Unknown client status: ${result}`,
      }));
      return;
    }

    const email = emailForCheck;
    dispatch(closeDialogCheck({}));

    switch (result) {
      case CheckClientResponse.REGISTERED_IN_BOTH:
        dispatch(setSearchField(email));
        filterOnClick();
        break;
      case CheckClientResponse.REGISTERED_IN_SYSTEM:
        dispatch((setEmailDialogAddToStudio(email)));
        dispatch(openDialogAddToStudio({}));
        break;
      case CheckClientResponse.NOT_REGISTERED:
        dispatch((setEmailDialogCreate(email)));
        dispatch(openDialogClientCreate({}));
        break;
    }
  };

  return (
    <Box sx={{
      height: 'calc(100vh - 102px)'
    }}>
      <ClientCheckDialog clientCheckCallBack={clientCheckCallBack} />
      <ClientAddToStudioDialog successCallback={filterOnClick} />
      <ClientCreateDialog successCallback={filterOnClick} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
        <Button onClick={addClient} startIcon={<ControlPointIcon />} variant='contained' sx={{ marginRight: '10px', padding: '11px 30px' }}>
          Add&nbsp;client
        </Button>
        <TextField
          label="Client (id, phone or email)"
          fullWidth
          value={searchField}
          onChange={(e) => dispatch(setSearchField(e.target.value))}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              void filterOnClick();
            }
          }}
          InputLabelProps={{
            style: {
              lineHeight: '1em'
            }
          }}
          InputProps={{
            style: {
              borderRadius: '15px',
              marginTop: '2px'
            }
          }}
          inputProps={{
            style: {
              padding: 12
            }
          }}
        ></TextField>
        <Button onClick={filterOnClick} startIcon={<SearchIcon />} variant='contained' sx={{ marginLeft: '10px', padding: '11px 30px' }}>
          Search
        </Button>
      </Box>

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        columns={columns}
        rowCount={rowCount}
        rows={data}
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={handlePageChange}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        filterMode='server'
      />
    </Box>
  );
};

export default ClientsPage;
