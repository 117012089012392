import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

export type StatisticsPageState = {
  sales: {
    open: boolean;
    fields: {
      dateFrom: string,
      dateTo: string,
    }
  },
  subscriptions: {
    open: boolean;
    fields: {
      dateFrom: string,
      dateTo: string,
    }
  }
};

const initialState: StatisticsPageState = {
  sales: {
    open: false,
    fields: {
      dateFrom: DateTime.now().minus({ month: 1 }).toISODate(),
      dateTo: DateTime.now().toISODate(),
    }
  },
  subscriptions: {
    open: false,
    fields: {
      dateFrom: DateTime.now().minus({ month: 1 }).toISODate(),
      dateTo: DateTime.now().toISODate(),
    }
  }
};

const statisticsPageSlice = createSlice<StatisticsPageState, SliceCaseReducers<StatisticsPageState>>({
  name: 'statistics',
  initialState,
  reducers: {
    openCloseDialogSalesReport: (state, action: PayloadAction<boolean>) => {
      state.sales.open = action.payload;
      Object.assign(state.sales.fields, initialState.sales.fields);
    },
    setInputSalesDateFrom: (state, action: PayloadAction<string>) => {
      state.sales.fields.dateFrom = action.payload;
    },
    setInputSalesDateTo: (state, action: PayloadAction<string>) => {
      state.sales.fields.dateTo = action.payload;
    },
    openCloseDialogSubscriptionsReport: (state, action: PayloadAction<boolean>) => {
      state.subscriptions.open = action.payload;
      Object.assign(state.subscriptions.fields, initialState.subscriptions.fields);
    },
    setInputSubscriptionsDateFrom: (state, action: PayloadAction<string>) => {
      state.subscriptions.fields.dateFrom = action.payload;
    },
    setInputSubscriptionsDateTo: (state, action: PayloadAction<string>) => {
      state.subscriptions.fields.dateTo = action.payload;
    },
  },
});

const statisticsReducer = statisticsPageSlice.reducer;

export const {
  openCloseDialogSalesReport,
  setInputSalesDateFrom,
  setInputSalesDateTo,
  openCloseDialogSubscriptionsReport,
  setInputSubscriptionsDateFrom,
  setInputSubscriptionsDateTo,
} = statisticsPageSlice.actions;

export default statisticsReducer;
