import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  AppointmentModel,
  ChangeSet,
  EditingState,
  IntegratedEditing,
  ViewState
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DateNavigator,
  Toolbar,
  Appointments,
  WeekView,
  AppointmentForm,
  Resources,
  DragDropProvider,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import React, { useEffect } from 'react';
import {
  AppointmentComponent, DateTimeField,
  DayScaleCell,
  LabelComponent,
  TextEditor,
  TimeTableCell
} from '../../components/appointments/components';
import { RoomDict } from '../../services/rooms/rooms.types';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  fetchAppointment,
  fetchSchedule,
  setStartOfActiveWeek,
  setEditingAppointment,
  setRoomId,
  setAddEditScheduleRecordForm,
  showWindowAppointmentInfo, setDisableSaveButton, setAppointmentChanges, clearSchedule, setScheduleStudioId,
} from './schedulePage.slice';
import { SourceAppointmentComponent } from '../../components/appointments/SourceAppointmentComponent';
import { DraftAppointmentComponent } from '../../components/appointments/DraftAppointmentComponent';
import { DateTime } from 'luxon';
import { currentDateToWeekInterval } from '../../utils/currentDateToWeekInterval';
import { fetchRoomsDict, prepareResources } from '../../store/dictionaries.slice';
import { ShowAppointment } from './components/ShowAppointment';
import { AppointmentCommandLayoutComponent } from './components/AppointmentCommandLayout';
import { IScheduleAdded, Schedule } from './schedulePage.types';
import SchedulesService from '../../services/schedules/schedules.service';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { checkIfConflicts } from './schedulePage.utils';
import { ScheduleEditDTO } from '../../services/schedules/schedules.types';
import _ from 'lodash';
import { Studio } from '../../services/studios/studios.types';
import { setWorkingTime } from '../applicationPage.slice';
import { InitialValues } from '../../types/types';
import { BookingAddDialog } from '../booking/BookingAddDialog';
import { loadLesson } from '../booking/bookingAddDialog.slice';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const defaultCurrentDate = DateTime.now().toJSDate(); // TODO: use timezone of studio
const isoParams = { includeOffset: false, suppressMilliseconds: false };

const allowChange = (record: Partial<AppointmentModel>): boolean => {
  const startDate = record.startDate instanceof Date ? DateTime.fromJSDate(record.startDate) : DateTime.fromISO(record.startDate as string);
  return startDate.diffNow().milliseconds > 0;
};

const allowDrag = (props: any) => {
  return allowChange(props);
};

const allowResize = () => false;

const SchedulePage = () => {
  const dispatch: ThunkDispatch<Schedule[], { roomId: number }, AnyAction> = useDispatch();

  const {
    data: appointments,
    roomId: _roomId,
    rooms,
    resources,
    startOfActiveWeek,
    showAddEditScheduleRecordForm,
    editingScheduleRecord,
    appointmentData,
    appointmentChanges,
    studioId: scheduleStudioId,
    disableSaveButton,
  } = useSelector((state: RootState) => state.schedule);
  const [roomId] = [Number(_roomId)]; // type check

  const { studioId, startHours, endHours, currentPage } = useSelector((state: RootState) => state.app);
  const { studios } = useSelector((state: RootState) => state.dictionaries);
  const { data: showAppointmentInfoData } = useSelector((state: RootState) => state.schedule.windows.showAppointmentInfo);

  const updateSchedule = async () => {
    if (_roomId !== InitialValues.EMPTY) {
      const [dateStart, dateEnd] = currentDateToWeekInterval(DateTime.fromISO(startOfActiveWeek));
      await dispatch(fetchSchedule({ roomId, dateStart, dateEnd }));
    } else {
      dispatch(clearSchedule({}));
    }
  }

  useEffect(() => {
    dispatch(setAddEditScheduleRecordForm(false));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!showAddEditScheduleRecordForm) {
      dispatch(setAppointmentChanges(0));
    }
  }, [dispatch, showAddEditScheduleRecordForm]);

  useEffect(() => {
    const isValid = !!(editingScheduleRecord?.limit && editingScheduleRecord?.serviceId && editingScheduleRecord.specialistId);
    dispatch(setDisableSaveButton(!isValid));
  }, [dispatch, editingScheduleRecord]);

  useEffect(() => {
    dispatch(clearSchedule({}));
    if (studioId !== InitialValues.EMPTY) {
      (async () => {
        await updateSchedule();
        const studio = studios.find((s) => s.id === studioId) as Studio;
        dispatch(setWorkingTime({ start: studio.workTimeStart, end: studio.workTimeEnd }))
        dispatch((setScheduleStudioId(studioId)));
      })();
    }
  }, [dispatch, startOfActiveWeek, roomId]);

  useEffect(() => {
    if (rooms[0]) {
      dispatch(setRoomId(rooms[0].id));
    } else {
      dispatch(setRoomId(InitialValues.EMPTY));
    }
  }, [dispatch, rooms]);

  useEffect(() => {
    dispatch(setAddEditScheduleRecordForm(false));
    dispatch(clearSchedule({}));
    dispatch(setRoomId(InitialValues.EMPTY));
    if (studioId !== InitialValues.EMPTY) {
      (async () => {
        await dispatch(prepareResources(studioId));
        await dispatch(fetchRoomsDict(studioId));
      })();
    }
  }, [dispatch, studioId, studios]);

  const appointmentHandler = async (data: ChangeSet) => {
    if (data?.changed?.['undefined']) {
      data.added = data.changed['undefined'];
      delete data.changed;
    }

    dispatch(setDisableSaveButton(true));

    if (data.deleted) {
      try {
        await SchedulesService.delete(data.deleted as string);
        dispatch(setAddEditScheduleRecordForm(false));

        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Successfully deleted schedule record',
        }));

      } catch(error: any) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Cannot delete schedule record: ' + error.message,
        }));
      }

      return;
    }

    if (data.added) {
      const added = editingScheduleRecord as Schedule;
      try {
        await SchedulesService.add({
          serviceId: Number(added.serviceId),
          specialistId: Number(added.specialistId),
          roomId,
          limit: Number(added.limit),
          start: DateTime.fromISO(added.startDate).toFormat('HH:mm'),
          end: DateTime.fromISO(added.endDate).toFormat('HH:mm'),
          date: DateTime.fromISO(added.startDate).toISODate() as string,
        });

        dispatch(setAddEditScheduleRecordForm(false));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Successfully added schedule record',
        }));
      } catch (e: any) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Cannot add schedule record: ' + e.message,
        }));
      }
    }

    if (data.changed) {
      const [[changeId, changed]] = Object.entries(data.changed);
      const params: ScheduleEditDTO = {
        limit: changed.limit,
        specialistId: changed.specialistId,
        start: changed.startDate ? DateTime.fromISO(changed.startDate).toFormat('HH:mm') : undefined,
        end: changed.endDate ? DateTime.fromISO(changed.endDate).toFormat('HH:mm') : undefined,
        date: (changed.startDate || changed.endDate) ? DateTime.fromISO(changed.startDate || changed.endDate).toISODate() as string : undefined,
      };

      try {
        await SchedulesService.edit(_.omitBy(params, _.isUndefined), changeId);

        if (showAddEditScheduleRecordForm) {
          dispatch(setAddEditScheduleRecordForm(false));
          dispatch(toggleDialogInfo({
            open: true,
            type: 'info',
            message: 'Schedule updated',
          }));
          setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 2000);
        }
      } catch(e: any) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Cannot edit schedule record: ' + e.message,
        }));
      }
    }

    void updateSchedule();
  };

  const allowAddOrChange = (record: Partial<AppointmentModel>): boolean => {
    if (record.startDate && record.endDate) {
      return !checkIfConflicts(appointments, record as IScheduleAdded) && allowChange(record);
    }
    return false;
  };

  const showAppointmentInfo = async ({ data: record }: { data: Schedule }) => {
    dispatch(showWindowAppointmentInfo(record.id));
    dispatch(loadLesson(record));
    dispatch(fetchAppointment({ id: record.id }));
  }

  const onCurrentDateChange = async (date: Date) => {
    // set "current" date as monday
    dispatch(setStartOfActiveWeek(DateTime.fromJSDate(date).startOf('week').toISO()));
  };

  const addScheduleButtonClick = () => {
    const date = DateTime.fromISO(startOfActiveWeek).diffNow().milliseconds > 0
      ? DateTime.fromISO(startOfActiveWeek)
      : DateTime.now().plus({ day: 1 });

    // First day of current week (or tomorrow) 12-00
    dispatch(setEditingAppointment({
      startDate: date.set({ hour: 12, minute: 0 }).toISO(isoParams),
      endDate: date.set({ hour: 13, minute: 0 }).toISO(isoParams),
    }));
    dispatch(setAddEditScheduleRecordForm(true));
  };

  const addScheduleFieldClick = (dateStart: Date) => {
    dispatch(setEditingAppointment({
      startDate: DateTime.fromJSDate(dateStart).toISO(isoParams),
      endDate: DateTime.fromJSDate(dateStart).plus({ hour: 1 }).toISO(isoParams),
    }));
    dispatch(setAddEditScheduleRecordForm(true));
  };

  const deleteAppointmentCallback = async (scheduleId: string): Promise<void> => {
    await dispatch(fetchAppointment({ id: scheduleId }));
    await updateSchedule();
  };

  return (
    <Box>
      <ShowAppointment deleteCallback={deleteAppointmentCallback} />
      <BookingAddDialog successCallback={updateSchedule} otherParticipants={showAppointmentInfoData} />

      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px'
      }}>

        <FormControl sx={{ marginLeft: '10px' }}>
          <InputLabel id="schedule-select-room-label">Room</InputLabel>
          <Select
            labelId="schedule-select-room-label"
            id="schedule-select-room"
            sx={{
              minWidth: '200px',
              borderRadius: '15px',
            }}
            label="Room"
            value={_roomId}
            onChange={(e) => dispatch(setRoomId(e.target.value))}
          >
            {rooms.map((room: RoomDict) => (
              <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{
          width: '100%'
        }} />

        <Box sx={{
          marginRight: '10px'
        }}>
          <Button
            variant="contained"
            color="primary"
            onClick={addScheduleButtonClick}
            startIcon={<AddCircleIcon />}
          >Add&nbsp;record</Button>
        </Box>
      </Box>

      <Scheduler
        height={"auto"}
        data={appointments}
        firstDayOfWeek={1}
        locale={"ru-RU"}
      >
        <ViewState
          defaultCurrentDate={defaultCurrentDate}
          currentDate={startOfActiveWeek}
          onCurrentDateChange={onCurrentDateChange}
        />
        <EditingState
          onCommitChanges={appointmentHandler}
          appointmentChanges={appointmentChanges}
          editingAppointment={editingScheduleRecord as AppointmentModel}
          // срабатывает при нажатии на пустое поле
          onAddedAppointmentChange={(addedAppointment: Partial<AppointmentModel>) => { // for cell click adding
            if (!showAddEditScheduleRecordForm && allowAddOrChange(addedAppointment)) {
              addScheduleFieldClick(addedAppointment.startDate as Date);
            }
          }}
          // срабатывает при изменении данных в форме
          onAppointmentChangesChange={(changes) => {
            if (changes.startDate && changes.startDate instanceof Date) {
              changes.startDate = DateTime.fromJSDate(changes.startDate).toISO(isoParams);
            }
            if (changes.endDate && changes.endDate instanceof Date) {
              changes.endDate = DateTime.fromJSDate(changes.endDate).toISO(isoParams);
            }
            if (Object.keys(changes).length > 0) {
              dispatch(setAppointmentChanges(changes));
            }
          }}
          onEditingAppointmentChange={(editingAppointment) => {
            if (editingAppointment) {
              dispatch(setEditingAppointment(editingAppointment));

              if (!editingAppointment.type && allowChange(editingAppointment)) { // for some reason if case is DD - this event is calling
                dispatch(setAddEditScheduleRecordForm(true));
              }
            }
          }}
        />
        <Toolbar/>
        <DateNavigator/>
        <WeekView
          startDayHour={startHours}
          endDayHour={endHours}
          timeTableCellComponent={TimeTableCell}
          dayScaleCellComponent={DayScaleCell}
        />
        <IntegratedEditing />
        <Appointments
          appointmentComponent={(data: any) => AppointmentComponent(
              { ...data, studioId: scheduleStudioId },
              allowChange,
            () => showAppointmentInfo(data),
          )}
        />
        <AppointmentForm
          visible={showAddEditScheduleRecordForm}
          appointmentData={appointmentData as AppointmentModel}
          textEditorComponent={TextEditor}
          labelComponent={LabelComponent}
          booleanEditorComponent={() => null}
          dateEditorComponent={DateTimeField}
          commandLayoutComponent={(data: any) => (<AppointmentCommandLayoutComponent {...data} disableSaveButton={disableSaveButton} />) }
        />
        <Resources
          data={resources}
          mainResourceName="roomId"
        />
        <DragDropProvider
          allowDrag={allowDrag}
          allowResize={allowResize}
          sourceAppointmentComponent={(data: any) => SourceAppointmentComponent({ ...data, isMatrix: false })}
          draftAppointmentComponent={(data: any) => DraftAppointmentComponent({ ...data, isMatrix: false })}
        />
        <CurrentTimeIndicator
          shadePreviousCells={true}
          shadePreviousAppointments={true}
          updateInterval={10000}
        />
      </Scheduler>
    </Box>
  );
};

export default React.memo(SchedulePage);
