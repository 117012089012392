import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store/store';
import { loadProfile, setInputLanguage, setInputName } from './myProfile.slice';
import myProfileService from '../../services/myProfile/myProfile.service';
import { openDialog } from '../../components/dialog/dialogInfo.slice';
import { DialogSuccessPasswordChanged } from './components/DialogSuccessPasswordChanged';
import validateForm from '../../utils/validation/validateForm';

const MyProfilePage = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [successChangedPasswordDialog, setSuccessChangedPasswordDialog] = useState(false);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { myName} = useSelector((state: RootState) => state.myProfile);

  const saveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;

    try {
      await myProfileService.edit({ name: myName });
      dispatch(openDialog({
        type: 'info',
        message: 'Successfully changed.',
      }));
    } catch(error: any) {
      dispatch(openDialog({
        type: 'error',
        message: 'Error while edit profile: ' + error.message,
      }));
    }
  };

  const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;

    if (newPassword !== repeatPassword) {
      dispatch(openDialog({
        type: 'info',
        message: 'Password and repeated password are not equal',
      }));
    }

    try {
      await myProfileService.changePassword(oldPassword, newPassword);
      setSuccessChangedPasswordDialog(true);
    } catch(error: any) {
      dispatch(openDialog({
        type: 'error',
        message: 'Cannot change password: ' + error.message,
      }));
    }
  };

  useEffect(() => {
    dispatch(loadProfile());
  },[dispatch]);

  const languages= ['English', 'Russian (Русский)', 'Georgian (ქართული)'];

  return (
    <Box width={400}>
      <Box component="form" onSubmit={saveChanges}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          required={true}
          value={myName}
          onChange={(e) => dispatch(setInputName(e.target.value))}
          sx={{ marginBottom: 1, marginTop: 1 }}
        />

        <Autocomplete
          options={languages.map((language) => ({
            id: language,
            label: language,
          }))}
          fullWidth
          disabled={true}
          disableClearable={true}
          inputValue={'English'}
          onChange={(_, newInputValue) => dispatch(setInputLanguage(newInputValue?.label || ''))}
          sx={{ marginBottom: 1, marginTop: 1 }}
          renderInput={(params) => <TextField
            {...params}
            label="Language"
            variant="outlined"
          />}
        />

        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 1 }}>Save Changes</Button>
      </Box>

      <Box sx={{ width: '100%', marginTop: 5, }} />

      <Box component="form" onSubmit={changePassword}>
        <DialogSuccessPasswordChanged open={successChangedPasswordDialog} />

        <TextField
          label="Old password"
          type="password"
          required={true}
          fullWidth
          sx={{ marginBottom: 1, marginTop: 1 }}
          onChange={(e) => setOldPassword(e.target.value)}
        />

        <TextField
          label="New password"
          type="password"
          required={true}
          fullWidth
          sx={{ marginBottom: 1, marginTop: 1 }}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <TextField
          label="Repeat new password"
          type="password"
          required={true}
          fullWidth
          sx={{ marginBottom: 1, marginTop: 1 }}
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 1 }}>Change password</Button>
      </Box>
    </Box>
  );
};

export default React.memo(MyProfilePage);
