import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Pricelist } from '../../services/pricelist/pricelist.types';
import PricelistService from '../../services/pricelist/pricelist.service';
import { fetchServiceGroupsDict } from '../../store/dictionaries.slice';
import { RootState } from '../../store/store';
import CompanyService from '../../services/company/company.service';

type DialogAddAction = {
  mode: 'add',
  serviceGroupId: number;
  duration: number;
  limit: number;
  price: number;
};

type DialogEditAction = {
  mode: 'edit',
  id: number,
  serviceGroupId: number;
  durationUnit: 'day' | 'month' | 'unlimited';
  durationValue: number | null;
  limit: number;
  price: number;
};

export type PricelistPageState = {
  currency: string,
  data: Pricelist[],
  dialogs: {
    addEdit: {
      isOpen: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null;
        serviceGroupId: number | '';
        limit: number | '';
        price: number | '';
        durationUnit: 'day' | 'month' | 'unlimited';
        durationValue: number | null;
      }
    },
    delete: {
      isOpen: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: PricelistPageState = {
  currency: '',
  data: [],
  dialogs: {
    addEdit: {
      isOpen: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        serviceGroupId: '',
        limit: '',
        price: '',
        durationUnit: 'day',
        durationValue: 30,
      }
    },
    delete: {
      isOpen: false,
      deletingId: null,
    },
  },
};

export const loadCurrencyInfo = createAsyncThunk<string>(
    'pricelist/loadCurrencyInfo',
    async () => {
      const company = await CompanyService.get();
      return company.currency;
    },
)

export const fetchPricelist = createAsyncThunk<Pricelist[], number>(
'pricelist/fetchPricelist',
async (studioId: number, { rejectWithValue, getState, dispatch }) => {
  try {
    let serviceGroups = (getState() as RootState).dictionaries.serviceGroups; // serviceGroups - не реактивная переменная!

    if (serviceGroups === null) {
      await dispatch(fetchServiceGroupsDict());
    }

    const pricelist = await PricelistService.getList(studioId);
    return pricelist;
  } catch (error: any) {
    return rejectWithValue('Error while fetch pricelist');
  }
});

const pricelistPageSlice = createSlice<PricelistPageState, SliceCaseReducers<PricelistPageState>>({
  name: 'pricelists',
  initialState,
  reducers: {
    closePricelistDialogAddEdit: (state) => {
      state.dialogs.addEdit.isOpen = false;
    },
    openPricelistDialogAddEdit: (state, action: PayloadAction<DialogAddAction | DialogEditAction>) => {
      state.dialogs.addEdit.isOpen = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.dialogs.addEdit.inputs, initialState.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.dialogs.addEdit.inputs, action.payload);
      }
    },
    closePricelistDialogDelete: (state) => {
      state.dialogs.delete.isOpen = false;
    },
    openPricelistDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.isOpen = true;
    },
    setInputHiddenId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.id = action.payload;
    },
    setInputServiceGroupId: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.serviceGroupId = action.payload;
    },
    setInputLimit: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.limit = action.payload;
    },
    setInputPrice: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.price = action.payload;
    },
    setInputDurationUnit: (state, action: PayloadAction<'day' | 'month' | 'unlimited'>) => {
      state.dialogs.addEdit.inputs.durationUnit = action.payload;
      let durationValue;
      switch (action.payload) {
        case 'day':
          durationValue = 30;
          break;
        case 'month':
          durationValue = 2;
          break;
        case 'unlimited':
          durationValue = null;
          break;
      }
      state.dialogs.addEdit.inputs.durationValue = durationValue;
    },
    setInputDurationValue: (state, action: PayloadAction<number>) => {
      state.dialogs.addEdit.inputs.durationValue = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricelist.fulfilled, (state: PricelistPageState, action) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
      })
      .addCase(loadCurrencyInfo.fulfilled, (state: PricelistPageState, action) => {
        state.currency = action.payload;
      })
  },
});

const pricelistReducer = pricelistPageSlice.reducer;

export const {
  closePricelistDialogAddEdit,
  openPricelistDialogAddEdit,
  closePricelistDialogDelete,
  openPricelistDialogDelete,
  setInputHiddenId,
  setInputServiceGroupId,
  setInputLimit,
  setInputPrice,
  setInputDurationUnit,
  setInputDurationValue,
} = pricelistPageSlice.actions;

export default pricelistReducer;
