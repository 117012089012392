import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Client } from '../../services/clients/clients.types';
import { RootState } from '../../store/store';
import { GetList } from '../../types/types';
import { GridPaginationModel } from '@mui/x-data-grid';
import clientsService from '../../services/clients/clients.service';

export type ClientsPageState = {
  rowCount: number,
  data: Client[],
  searchField: string,
  paginationModel: GridPaginationModel;
  dialogs: {
    check: {
      openDialog: boolean,
      email: string,
    },
    addToStudio: {
      openDialog: boolean,
      email: string,
    },
    create: {
      openDialog: boolean,
      email: string,
      phone: string,
      name: string,
      surname: string,
      instagram: string,
      telegram: string,
      whatsapp: string,
    },
  }
};

const initialState: ClientsPageState = {
  rowCount: 0,
  data: [],
  searchField: '',
  paginationModel: {
    page: 0,
    pageSize: 50,
  },
  dialogs: {
    check: {
      openDialog: false,
      email: '',
    },
    addToStudio: {
      openDialog: false,
      email: '',
    },
    create: {
      openDialog: false,
      email: '',
      name: '',
      surname: '',
      phone: '',
      instagram: '',
      telegram: '',
      whatsapp: '',
    }
  }
};

export type Filter = [{
  id: number,
}] | [{
  email: string
}, {
  phoneNumber: string
}]

export const fetchClients = createAsyncThunk<GetList<Client>, { studioId: number, filter?: Filter } >(
'services/fetchClients',
async ({ studioId, filter }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;

    const response = await clientsService.getList({
      studioId,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      page: state.clients.paginationModel.page + 1,
      pageSize: state.clients.paginationModel.pageSize,
    });
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch clients');
  }
});

const usersPageSlice = createSlice<ClientsPageState, SliceCaseReducers<ClientsPageState>>({
  name: 'clients',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.paginationModel.page = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    openClientCheck: (state) => {
      state.dialogs.check.openDialog = true;
    },
    closeDialogCheck: (state) => {
      state.dialogs.check.email = '';
      state.dialogs.check.openDialog = false;
    },
    openDialogAddToStudio: (state) => {
      state.dialogs.addToStudio.openDialog = true;
    },
    closeDialogAddToStudio: (state) => {
      state.dialogs.addToStudio.openDialog = false;
    },
    openDialogClientCreate: (state) => {
      state.dialogs.create.openDialog = true;
    },
    closeDialogClientCreate: (state) => {
      state.dialogs.create.email = '';
      state.dialogs.create.phone = '';
      state.dialogs.create.name = '';
      state.dialogs.create.surname = '';
      state.dialogs.create.openDialog = false;
    },
    setInputEmailCheckUser: (state, action: PayloadAction<string>) => {
      state.dialogs.check.email = action.payload;
    },
    setEmailDialogAddToStudio: (state, action: PayloadAction<string>) => {
      state.dialogs.addToStudio.email = action.payload;
    },
    setEmailDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.email = action.payload;
    },
    setPhoneDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.phone = action.payload;
    },
    setNameDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.name = action.payload;
    },
    setSurnameDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.surname = action.payload;
    },
    setInstagramDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.instagram = action.payload;
    },
    setTelegramDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.telegram = action.payload;
    },
    setWhatsAppDialogCreate: (state, action: PayloadAction<string>) => {
      state.dialogs.create.whatsapp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.fulfilled, (state: ClientsPageState, action) => {
        state.rowCount = action.payload.total;
        state.data = action.payload.elements;
      });
  },
});

const clientsReducer = usersPageSlice.reducer;

export const {
  setPage,
  setSearchField,
  openClientCheck,
  closeDialogCheck,
  openDialogClientCreate,
  closeDialogClientCreate,
  setInputEmailCheckUser,
  setEmailDialogAddToStudio,
  openDialogAddToStudio,
  closeDialogAddToStudio,
  setEmailDialogCreate,
  setPhoneDialogCreate,
  setNameDialogCreate,
  setSurnameDialogCreate,
  setInstagramDialogCreate,
  setTelegramDialogCreate,
  setWhatsAppDialogCreate,
} = usersPageSlice.actions;

export default clientsReducer;
