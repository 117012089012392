import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export type DialogInfoState = {
  type: 'info' | 'error',
  open: boolean,
  message: string;
};

const initialState: DialogInfoState = {
  type: 'info',
  open: false,
  message: '',
};

const dialogInfoSlice = createSlice<DialogInfoState, SliceCaseReducers<DialogInfoState>>({
  name: 'dialog-info',
  initialState,
  reducers: {
    toggleDialogInfo: (state, action: PayloadAction<{
      open: false
    } | { open: true, type: 'info' | 'error', message: string }>) => {
      state.open = action.payload.open;
      if (action.payload.open) {
        state.message = action.payload.message;
        state.type = action.payload.type;
      }
    },
    openDialog: (state, action: PayloadAction<{ type: 'info' | 'error', message: string}>) => {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    closeDialog: (state) => {
      state.open = false;
    },
    openError: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.type = 'error';
      state.message = action.payload;
    },
    openInfo: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.type = 'info';
      state.message = action.payload;
    },
  },
});

const dialogInfoReducer = dialogInfoSlice.reducer;

export const {
  toggleDialogInfo ,
  openDialog,
  openError,
  openInfo,
  closeDialog,
} = dialogInfoSlice.actions;

export default dialogInfoReducer;
