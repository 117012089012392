export type GetList<T> = {
  elements: T[];
  total: number;
}

export type IsoDateString = string;

export enum InitialValues {
  EMPTY = '',
  NO_OPTIONS = -2,
}
