import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import SchedulesService from '../../services/schedules/schedules.service';
import { Participant, Schedule, ScheduleResources } from './schedulePage.types';
import { DateTime } from 'luxon';
import { RoomDict } from '../../services/rooms/rooms.types';
import { fetchRoomsDict, prepareResources } from '../../store/dictionaries.slice';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { InitialValues } from '../../types/types';

export type SchedulePageState = {
  data: Schedule[],
  roomId: number | '',
  studioId: number | '', // используется для определения справочника тренеров. является копией app->studioId, но устанавливается после обновления данных
  rooms: RoomDict[],
  startOfActiveWeek: string,
  showAddEditScheduleRecordForm: boolean;
  editingScheduleRecord: Partial<Schedule & AppointmentModel>;
  appointmentData: Partial<Schedule & AppointmentModel>;
  appointmentChanges: Partial<Schedule & AppointmentModel>;
  disableSaveButton: boolean;
  resources: ScheduleResources,
  windows: {
    showAppointmentInfo: {
      scheduleId: string,
      open: boolean,
      data: Participant[],
      windows: {
        delete: {
          open: boolean,
          deletingId: number | null,
        }
      }
    },
  },
};

const initialState: SchedulePageState = {
  data: [],
  roomId: InitialValues.EMPTY,
  studioId: InitialValues.EMPTY,
  rooms: [],
  startOfActiveWeek: DateTime.now().startOf('week').toISO(),
  showAddEditScheduleRecordForm: false,
  editingScheduleRecord: {},
  appointmentData: {},
  appointmentChanges: {},
  disableSaveButton: true,
  windows: {
    showAppointmentInfo: {
      scheduleId: '',
      open: false,
      data: [],
      windows: {
        delete: {
          open: false,
          deletingId: null,
        }
      }
    },
  },
  resources: [{
    fieldName: 'specialistId',
    title: 'Specialist',
    instances: [],
  }, {
    fieldName: 'serviceId',
    title: 'Service',
    instances: [],
  }, {
    fieldName: 'limit',
    title: 'Limit',
    instances: Array.from({ length: 20 }).map((_, index) => ({
      id: index + 1,
      text: (index + 1).toString(),
    })),
  }],
};

export const fetchSchedule = createAsyncThunk<Schedule[], { roomId: number, dateStart: string, dateEnd: string }>(
  'schedule/fetchSchedule',
  async ({ roomId, dateStart, dateEnd }, { rejectWithValue }) => {
  try {
    const response = await SchedulesService.getList(roomId, dateStart, dateEnd);
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch appointments');
  }
});

export const fetchAppointment = createAsyncThunk<Participant[], { id: string }>(
    'schedule/fetchParticipants',
    async ({ id }, { rejectWithValue }) => {
      try {
        const response = await SchedulesService.getParticipants(id);
        return response;
      } catch (error: any) {
        return rejectWithValue('Error while fetch appointment participants');
      }
    });

const schedulePageSlice = createSlice<SchedulePageState, SliceCaseReducers<SchedulePageState>>({
  name: 'schedule',
  initialState,
  reducers: {
    clearSchedule: (state: SchedulePageState) => {
      state.data = [];
    },
    showWindowAppointmentInfo: (state: SchedulePageState, action: PayloadAction<string>) => {
      state.windows.showAppointmentInfo.scheduleId = action.payload;
      state.windows.showAppointmentInfo.open = true;
    },
    closeWindowAppointmentInfo: (state: SchedulePageState) => {
      state.windows.showAppointmentInfo.data = [];
      state.windows.showAppointmentInfo.open = false;
    },
    setRoomId: (state: SchedulePageState, action: PayloadAction<number>) => {
      state.roomId = action.payload;
    },
    setStartOfActiveWeek: (state: SchedulePageState, action: PayloadAction<string>) => {
      state.startOfActiveWeek = action.payload;
    },
    setAddEditScheduleRecordForm: (state: SchedulePageState, action: PayloadAction<boolean>) => {
      state.showAddEditScheduleRecordForm = action.payload;
    },
    setEditingAppointment:(state: SchedulePageState, action: PayloadAction<AppointmentModel & Schedule>) => {
      state.editingScheduleRecord = action.payload;
      state.appointmentData = action.payload;
    },
    setDisableSaveButton: (state: SchedulePageState, action: PayloadAction<boolean>) => {
      state.disableSaveButton = action.payload;
    },
    setAppointmentChanges: (state: SchedulePageState, action: PayloadAction<AppointmentModel & Schedule>) => {
      state.appointmentChanges = action.payload;
      Object.assign(state.editingScheduleRecord, action.payload);
    },
    setScheduleStudioId: (state: SchedulePageState, action: PayloadAction<number>): void => {
      state.studioId = action.payload;
    },
    openWindowDeleteAppointment: (state: SchedulePageState, action: PayloadAction<number>) => {
      state.windows.showAppointmentInfo.windows.delete.open = true;
      state.windows.showAppointmentInfo.windows.delete.deletingId = action.payload;
    },
    closeWindowDeleteAppointment: (state: SchedulePageState) => {
      state.windows.showAppointmentInfo.windows.delete.open = false;
      state.windows.showAppointmentInfo.windows.delete.deletingId = null;
    },
    setDeleteAppointmentDeletingId: (state: SchedulePageState, action: PayloadAction<number>) => {
      state.windows.showAppointmentInfo.windows.delete.deletingId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomsDict.fulfilled, (state: SchedulePageState, action) => {
        state.rooms = action.payload;
      })
      .addCase(fetchSchedule.fulfilled, (state: SchedulePageState, action) => {
        state.data.length = 0;
        state.data.push(...action.payload);
      })
      .addCase(prepareResources.fulfilled, (state: SchedulePageState, action) => {
        state.resources[0].instances = action.payload.specialists.map((specialist) => ({
          id: specialist.id,
          text: specialist.name,
        }));

        state.resources[1].instances = action.payload.services.map((service) => ({
          id: service.id,
          text: service.name,
        }));
      })
      .addCase(fetchAppointment.fulfilled, (state: SchedulePageState, action) => {
        state.windows.showAppointmentInfo.data = action.payload;
      })
  },
});

const scheduleReducer = schedulePageSlice.reducer;

export const {
  clearSchedule,
  setRoomId,
  setStartOfActiveWeek,
  setAddEditScheduleRecordForm,
  closeWindowAppointmentInfo,
  showWindowAppointmentInfo,
  setEditingAppointment,
  setAppointmentChanges,
  setDisableSaveButton,
  setScheduleStudioId,
  openWindowDeleteAppointment,
  closeWindowDeleteAppointment,
} = schedulePageSlice.actions;

export default scheduleReducer;
