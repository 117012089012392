import { ReportSales1Params } from './statistics.types';
import { ApiService } from '../api/api.service';
import { AxiosResponse } from 'axios';

class StatisticsService {
  readonly url = 'reports';

  public async reportSales1(params: ReportSales1Params): Promise<AxiosResponse<any>> {
    return ApiService.get(`${this.url}/sales?dateStart=${params.dateFrom}&dateEnd=${params.dateTo}`, {
      responseType: 'blob'
    });
  }

  public async reportSubscriptions(params: ReportSales1Params): Promise<AxiosResponse<any>> {
    return ApiService.get(`${this.url}/subscriptions?dateStart=${params.dateFrom}&dateEnd=${params.dateTo}`, {
      responseType: 'blob'
    });
  }
}

const statisticsService = new StatisticsService();

export default statisticsService;
