import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import clientPhoto from '../../../assets/images/client.png';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import SubscriptionsService from '../../../services/subscriptions/subscriptions.service';
import { SubscriptionWithClient } from '../../subscriptions/subscriptions.types';
import validateForm from '../../../utils/validation/validateForm';

const SubscriptionEditDialog = ({
  isOpen,
  setOpened,
  subscription,
  callback,
}: {
  isOpen: boolean,
  setOpened: Dispatch<SetStateAction<boolean>>,
  subscription: SubscriptionWithClient,
  callback: () => any,
}) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const dialogClose = () => {
    setOpened(false);
  };

  useEffect(() => {
    if (subscription) {
      setComment(subscription.comment);
      setStart(subscription.dateStart);
      setEnd(subscription.dateEnd ?? '');
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;

    try {
      await SubscriptionsService.edit(subscription.id, {
        dateStart: start,
        dateEnd: end,
        comment,
      });
      setOpened(false);
      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Subscription edited',
      }));
      callback();
    } catch (err: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while edit subscription: ' + err.message,
      }));
    }
  };

  return (
    <Dialog open={isOpen} onClose={dialogClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit subscription</DialogTitle>
        <DialogContent>
          <Box sx={{
            display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
            border: '2px solid #D9D9D9',
            borderRadius: '16px',
            padding: '20px'
          }}>
            <Box sx={{height: '120px', marginRight: '10px'}}>
              <img src={clientPhoto} height={'120px'}/>
            </Box>
            <Box sx={{
              display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', width: '100%'
            }}>
              <Box sx={{width: '100%'}}>
                {subscription.client.name} {subscription.client.surname}
                <br/>
                {subscription.client.phone}
                <br/>
                {subscription.client.email}
              </Box>
              <Box sx={{marginLeft: 'auto'}}>
                ID:&nbsp;{subscription.client.id}
              </Box>
            </Box>
          </Box>

          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <DatePicker
                label="Subscription start"
                value={start}
                onChange={(dateTime: DateTime | null) => setStart(dateTime?.toFormat('yyyy-MM-dd') ?? '')}
                renderInput={(props) => <TextField {...props} sx={{
                  marginTop: '20px',
                  marginRight: '10px',
                  width: '50%'
                }}/>}
              />
              <DatePicker
                label="Subscription end"
                value={end}
                onChange={(dateTime: DateTime | null) => setEnd(dateTime?.toFormat('yyyy-MM-dd') ?? '')}
                renderInput={(props) => <TextField {...props} sx={{
                  marginTop: '20px',
                  width: '50%'
                }}/>}
              />
            </Box>
          </LocalizationProvider>

          <TextField
            label="Comment"
            variant="outlined"
            fullWidth
            multiline={true}
            rows={4}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            sx={{ marginTop: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">Edit subscription</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};


export default SubscriptionEditDialog;
