import CustomNoRowsOverlay from '../../../components/grid/CustomNoRowsOverlay';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ClientGroup } from '../../../services/clientGroups/clientGroups.types';
import { Checkbox } from '@mui/material';
import ClientsService from '../../../services/clients/clients.service';
import { openError, toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { loadClientData } from '../clientCardPage.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

type ClientGroupRecord = ClientGroup & {
  included: boolean;
}

const GroupsGrid = () => {
  const [gridData, setGridData] = useState<ClientGroupRecord[]>([]);
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { groups: groupsOfClient, clientId} = useSelector((state: RootState) => state.clientCard);
  const { clientGroups } = useSelector((state: RootState) => state.dictionaries);

  const handleCheckboxChange = async (id: number, included: boolean) => {
    if (!clientId) { dispatch(openError(`Client not found`)); return; }

    const currentGroups = gridData.map(record =>
      record.id === id ? { ...record, included } : record
    );

    setGridData(currentGroups);

    const currentGroupIds = currentGroups
      .filter((record) => record.included)
      .map((record) => record.id);

    try {
      await ClientsService.setGroups(clientId, currentGroupIds);
      dispatch(loadClientData(clientId));
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while set groups for client: ' + error.message,
      }));
    }
  };

  const columnsGroups: GridColDef[] = [{
    field: 'id',
  },{
    field: 'included',
    headerName: 'Included',
    width: 100,
    renderCell: (params) => (
      <Checkbox
        checked={params.row.included}
        onChange={(event) => handleCheckboxChange(params.row.id, event.target.checked)}
      />
    ),
  },{
    field: 'name',
    headerName: 'Group',
    flex: 1
  },{
    field: 'description',
    headerName: 'Description',
    flex: 2
  }];

  useEffect(() => {
    const groupIdsOfClient = groupsOfClient.map((group) => group.id);

    setGridData(clientGroups.map((group) => ({
      ...group,
      included: groupIdsOfClient.includes(group.id),
    })));
  }, [groupsOfClient, clientGroups]);

  return (
    <>
      <DataGrid
        sx={{
          '&, [class^=MuiDataGrid-root]': { border: 'none' },
          height: 'calc(100vh - 375px)',
          width: '100%'
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        slots={{ noRowsOverlay: () => <CustomNoRowsOverlay text={"No groups"} /> }}
        columns={columnsGroups}
        rows={gridData}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </>
  );
};

export default GroupsGrid;
