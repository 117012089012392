import { ThunkDispatch } from '@reduxjs/toolkit';
import { Service } from '../../../services/services/services.types';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import servicesService from '../../../services/services/services.service';
import {
  closeServiceDialogDelete,
  fetchServices,
  openServiceDialogAddEdit,
  openServiceDialogDelete
} from '../servicesPage.slice';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useEffect } from 'react';
import { DialogDelete } from '../../../components/DialogDelete';
import { ServiceDialogAddEdit } from './ServiceDialogAddEdit';
import { getServiceGroupsWithDeleted } from '../../../store/dictionaries.selectors';

const ServicesGrid = () => {
  const dispatch: ThunkDispatch<Service[], any, AnyAction> = useDispatch();

  const {
    data: services,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.services.services);

  const serviceGroups = useSelector(getServiceGroupsWithDeleted);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await servicesService.delete(deletingId);
        dispatch(fetchServices());
        dispatch(closeServiceDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Service successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete service: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'photo',
    headerName: 'Photo',
    width: 80,
    renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={ params.row.photo || '/logo96.png' } width={40} alt={"params.row.name"} />
        </div>
    ),
  },{
    field: 'name',
    headerName: 'Name',
    width: 200
  },{
    field: 'serviceGroups',
    headerName: 'Groups',
    width: 200,
    renderCell: (params) => {
      const groupNames = params.value
          .map((group: { id: number }) => serviceGroups.find((g) => g.id === group.id)?.name || 'not found');

      if (groupNames.includes('not found')) {
        return 'not found';
      }

      const r = groupNames.map((role: string) => (<li key={role}>{role}<br /></li>));

      return (<ul style={{ padding: 0, listStyle: 'none' }}>{r}</ul>)
    }
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'hidden',
    headerName: 'Visible',
    align: 'center',
    width: 65,
    renderCell: (params) => (
      <>
        {!params.row.hidden && (<VisibilityIcon sx={{ opacity: 0.54 }} />)}
        {params.row.hidden && (<VisibilityOffIcon sx={{ opacity: 0.54 }} />)}
      </>
    ),
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openServiceDialogAddEdit({
          mode: 'edit',
          ...params.row,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openServiceDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openServiceDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Add service</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this service?"
        onClose={() => dispatch(closeServiceDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <ServiceDialogAddEdit />

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        hideFooter={true}
        rows={services}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}  // Автоматическая высота строк
      />
    </>
  );
};

export default ServicesGrid;
