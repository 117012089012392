import { IconButton } from '@mui/material';
import React from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { openDialog } from '../../components/dialog/dialogInfo.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { openCloseDialogSalesReport, openCloseDialogSubscriptionsReport } from './statisticsPage.slice';
import { StatisticsSalesReportDialog } from './sales/StatisticsSalesReportDialog';
import { StatisticsSubscriptionsReportDialog } from './sales/StatisticsSubscriptionsReportDialog';

const StatisticsPage = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const openReport = (reportId: string) => {
    switch (reportId) {
      case '1_SALES':
        dispatch(openCloseDialogSalesReport(true));
        break;
      case '2_SUBSCRIPTIONS':
        dispatch(openCloseDialogSubscriptionsReport(true));
        break;
      default:
        dispatch(openDialog({
          type: 'error',
          message: `Report ${reportId} is unavailable`
        }));
    }
  };

  const columns: GridColDef[] = [{
    field: 'number',
    headerName: 'Number',
    sortable: false,
    flex: 1,
  }, {
    field: 'group',
    headerName: 'Group',
    sortable: false,
    flex: 1,
  }, {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    width: 200
  }/*, {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    width: 200
  }*/, {
    field: 'start',
    headerName: '',
    width: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <IconButton onClick={() => openReport(params.row.id)}>
        <PlayCircleIcon />
      </IconButton>
    ),
  }];

  const data = [{
    id: '1_SALES',
    number: '1.1',
    group: 'Finances',
    name: 'Sales',
    description: 'Sales'
  }, {
    id: '2_SUBSCRIPTIONS',
    number: '1.2',
    group: 'Finances',
    name: 'Subscriptions',
    description: 'Subscriptions'
  }];

  return (
    <>
      <h2>Reports</h2>
      <DataGrid
          sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
          columns={columns}
          rows={data}
          hideFooter={true}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
      />

      <StatisticsSalesReportDialog />
      <StatisticsSubscriptionsReportDialog />
    </>
  );
}

export default StatisticsPage;
