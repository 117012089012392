import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from '../../config/config';
import AuthService from '../auth/auth.service';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof AxiosError && error.response?.status === 401) {
      if (error.config?.url?.includes('/login')) {
        const e = new Error(error?.response?.data.message || 'Invalid username or password');
        return Promise.reject(e);
      }

      AuthService.logout();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export class ApiService {
  private static readonly apiUrl = API_URL;

  private static getRequestConfig() {
    const token = localStorage.getItem('user');

    return {
      headers: { Authorization: `Bearer ${token}` }
    };
  }

  private static getHeadersForUpload() {
    const token = localStorage.getItem('user');

    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
    };
  }

  public static async delete(url: string): Promise<AxiosResponse> {
    const result = await axios.delete(ApiService.apiUrl + url, ApiService.getRequestConfig());
    return result;
  }

  public static async post<T>(url: string, body: T): Promise<AxiosResponse> {
    const result = await axios.post<T>(ApiService.apiUrl + url, body, ApiService.getRequestConfig());
    return result;
  }

  public static async upload<T>(url: string, body: T): Promise<AxiosResponse> {
    const result = await axios.post<T>(ApiService.apiUrl + url, body, ApiService.getHeadersForUpload());
    return result;
  }

  public static async patch<T>(url: string, body: T): Promise<AxiosResponse> {
    const result = await axios.patch<T>(ApiService.apiUrl + url, body, ApiService.getRequestConfig());
    return result;
  }

  public static async get<T>(url: string, config?: Record<string, any>): Promise<AxiosResponse<T>> {
    const result = await axios.get<T>(ApiService.apiUrl + url, {
      ...ApiService.getRequestConfig(),
      ...config,
    });
    return result;
  }

  public static async put<T>(url: string, body: Record<string, any>): Promise<AxiosResponse> {
    try {
      const result = await axios.put<T>(ApiService.apiUrl + url, body, ApiService.getRequestConfig());
      return result;
    } catch(e: unknown) {
      if (e instanceof AxiosError) {
        console.error(e?.response?.data?.message)
      }

      throw e;
    }

  }
}
