import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { setDialogConflictsOpen, SpecialistDeleteConflictReason, SpecialistDeleteConflictReasonConflict } from '../specialistsPage.slice';

const renderMessage = (reasons: SpecialistDeleteConflictReason[]) => {
  if (!reasons || reasons.length === 0) {
    return null;
  }

  return reasons.map((reason: SpecialistDeleteConflictReason) => (
      <div key={reason.calendar}>
        <strong>{reason.calendar}</strong>
        {reason.conflicts.map((conflict: SpecialistDeleteConflictReasonConflict) => (
            <div key={`${reason.calendar}-${conflict.day}`}>{conflict.day} ({conflict.timeStart} - {conflict.timeEnd})</div>
        ))}
      </div>
  ));
};

export const SpecialistPageConflictDialog = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { open, reasons } = useSelector((state: RootState) => state.specialists.dialogs.deleteConflict);

  return (
      <Dialog open={open}>
        <DialogTitle>Conflicts</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={ () => dispatch(setDialogConflictsOpen(false))}
            sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <p>Error while delete specialist:</p>
          {renderMessage(reasons)}
        </DialogContent>
      </Dialog>
  );
}
