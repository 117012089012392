import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { InitialValues } from '../../types/types';
import { Client } from '../../services/clients/clients.types';
import { Schedule } from '../schedule/schedulePage.types';
import { Subscription } from '../subscriptions/subscriptions.types';
import SubscriptionsService from '../../services/subscriptions/subscriptions.service';
import { IGetActive } from '../../services/subscriptions/subscriptions.types';

export type BookingAddState = {
  openDialog: boolean;
  client: string;
  subscriptions: Subscription[],
  subscriptionId: number | '',
  clientVisibility: boolean,
  clientCard: {
    id: number | '';
    name: string;
    surname: string;
    phoneNumber: string;
    photo: string;
    email: string;
    instagram: string;
    whatsapp: string;
    telegram: string;
  },
  lesson: {
    scheduleId: string;
    serviceId: number | '';
    specialistId: number | '';
    startDate: string;
    endDate: string;
  }
};

const initialState: BookingAddState = {
  openDialog: false,
  client: '',
  subscriptions: [],
  subscriptionId: InitialValues.EMPTY,
  clientVisibility: false,
  clientCard: {
    id: InitialValues.EMPTY,
    name: '',
    surname: '',
    phoneNumber: '',
    photo: '',
    email: '',
    instagram: '',
    whatsapp: '',
    telegram: '',
  },
  lesson: {
    scheduleId: '',
    serviceId: InitialValues.EMPTY,
    specialistId: InitialValues.EMPTY,
    startDate: '',
    endDate: '',
  },
};

export const loadSubscriptions = createAsyncThunk<Subscription[], IGetActive>(
    'bookings/loadSubscriptions',
    async (params: IGetActive, { rejectWithValue }) => {
      try {
        const response = await SubscriptionsService.active(params);
        return response;
      } catch (error: any) {
        return rejectWithValue('Error while fetch studios');
      }
    });

const bookingAddSlice = createSlice<BookingAddState, SliceCaseReducers<BookingAddState>>({
  name: 'booking',
  initialState,
  reducers: {
    openDialogBookingAdd: (state) => {
      state.openDialog = true;
    },
    closeDialogBookingAdd: (state) => {
      state.openDialog = false;
    },
    resetClient: (state) => {
      state.client = '';
      state.clientCard = initialState.clientCard;
      state.clientVisibility = false;
    },
    setInputClient: (state, action: PayloadAction<string>) => {
      state.client = action.payload;
    },
    loadClient: (state, action: PayloadAction<Client>) => {
      Object.assign(state.clientCard, action.payload);
    },
    loadLesson: (state, action: PayloadAction<Schedule>) => {
      Object.assign(state.lesson, {
        ...action.payload,
        scheduleId: action.payload.id,
      });
    },
    setSubscriptions: (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptions = action.payload;
    },
    setSubscriptionId: (state, action: PayloadAction<number>) => {
      state.subscriptionId = action.payload;
    },
    setClientVisibility: (state, action: PayloadAction<boolean>) => {
      state.clientVisibility = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSubscriptions.fulfilled, (state: BookingAddState, action) => {
        state.subscriptions = action.payload;

        if (action.payload.length > 0) {
          state.subscriptionId = InitialValues.EMPTY;
        } else {
          state.subscriptionId = InitialValues.NO_OPTIONS;
        }

      });
  },
});

const bookingAddReducer = bookingAddSlice.reducer;

export const {
  openDialogBookingAdd,
  closeDialogBookingAdd,
  setInputClient,
  loadClient,
  loadLesson,
  setSubscriptions,
  setSubscriptionId,
  setClientVisibility,
  resetClient,
} = bookingAddSlice.actions;

export default bookingAddReducer;
