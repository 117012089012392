import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { closeDialogAddToStudio } from '../clientsPage.slice';
import ClientsService from '../../../services/clients/clients.service';
import { openError, toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import validateForm from '../../../utils/validation/validateForm';

export const ClientAddToStudioDialog = (props: { successCallback: () => void }) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { openDialog, email } = useSelector((state: RootState) => state.clients.dialogs.addToStudio);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => {
    dispatch(closeDialogAddToStudio({}));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    validateForm(e); // do nothing, if (!validateForm(e)) return;
    if (!studioId) { dispatch(openError(`Studio not found`)); return; }

    try {
      await ClientsService.addToStudio(email, studioId);
      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully added to studio.`,
      }));
      dispatch(closeDialogAddToStudio({}));
      props.successCallback();
    } catch(err: unknown) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while add to studio: ${(err instanceof Error && err?.message) || err}`,
      }));
    }
  };

  return (
    <Dialog open={openDialog} onClose={dialogClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add client</DialogTitle>
        <DialogContent>
          Add client with e-mail {email} to studio?
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">Add to studio</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

