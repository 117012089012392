import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import React from 'react';

import {
  closeServiceGroupDialogAddEdit,
  fetchServiceGroups,
  ServicesPageState,
  setServiceGroupInputDescription,
  setServiceGroupInputName,
} from '../servicesPage.slice';
import { RootState } from '../../../store/store';
import { closeDialog, openError, openInfo } from '../../../components/dialog/dialogInfo.slice';
import serviceGroupsService from '../../../services/serviceGroups/serviceGroups.service';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';
import validateForm from '../../../utils/validation/validateForm';

export const ServiceGroupDialogAddEdit = () => {
  const dispatch: ThunkDispatch<ServicesPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { id, name, description },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.services.serviceGroups.dialogs.addEdit);

  const dialogClose = () => dispatch(closeServiceGroupDialogAddEdit({}));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, mode: 'add' | 'edit') => {
    if (!validateForm(e)) return;

    let textAction, textActionPast;
    const body = { name, description };

    try {
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];
        await serviceGroupsService.add(body);
      } else {
        if (!id) { dispatch(openError('ID is undefined')); return; }
        [textAction, textActionPast] = ['edit', 'edited'];

        await serviceGroupsService.edit(id, body);
      }

      dispatch(fetchServiceGroups());
      dispatch(fetchServiceGroupsDict());
      dialogClose();

      dispatch(openInfo(`Successfully ${textActionPast}.`));
      setTimeout(() => dispatch(closeDialog(0)), 3000);
    } catch (error: any) {
      dispatch(openError(`Error while ${textAction} service: ${error.message}`));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <form onSubmit={(e) => handleSubmit(e, mode)}>
        <DialogTitle>{mode === 'add' ? 'Add service group' : 'Edit service group'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setServiceGroupInputName(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
            required={true}
          />
          <TextField
            label="Description"
            multiline={true}
            rows={6}
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => dispatch(setServiceGroupInputDescription(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">{mode === 'edit' ? 'Save Changes' : 'Add service group'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
