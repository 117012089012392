import { SubscriptionDTO } from '../subscriptions/subscriptions.types';
import { IGetListByStudioRequest } from '../base/base.types';
import { ClientGroup, ClientGroupDTO } from '../clientGroups/clientGroups.types';

export type Client = {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
};

type BookingDTO = {
  id: number,
  roomId: number,
  specialistId: number,
  serviceId: number,
  clientId: number,
  event: {
    start: string,
    end: string,
    date: string
  },
}

export type ClientExtendedDTO = {
  id: number,
  email: string,
  name: string,
  surname: string,
  photo: string,
  phoneNumber: string,
  instagram: string,
  whatsapp: string,
  telegram: string,
  subscriptions: SubscriptionDTO[],
  bookings: BookingDTO[],
  groups: ClientGroupDTO[],
}

export type ClientExtended = {
  id: number,
  email: string,
  name: string,
  surname: string,
  photo: string,
  phoneNumber: string,
  instagram: string,
  whatsapp: string,
  telegram: string,
  subscriptions: SubscriptionDTO[],
  bookings: BookingDTO[],
  groups: ClientGroup[],
}

export type GetListRequest = IGetListByStudioRequest & {
  filter?: string;
}

export enum CheckClientResponse {
  NOT_REGISTERED = 'NOT_REGISTERED',
  REGISTERED_IN_SYSTEM = 'REGISTERED_IN_SYSTEM',
  REGISTERED_IN_BOTH = 'REGISTERED_IN_BOTH',
}

export type ClientCreate = {
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  instagram?: string,
  whatsapp?: string,
  telegram?: string,
  studioId: number;
};

export type ClientCreateDTO = ClientCreate;
