import { Appointments, DragDropProvider } from '@devexpress/dx-react-scheduler-material-ui';
import React from 'react';
import { AppointmentComponentContent } from './AppointmentComponentContent';

export const DraftAppointmentComponent = ({ data, ...restProps }: DragDropProvider.DraftAppointmentProps & { studioId: number, isMatrix: boolean }) =>  {
  return (
      <Appointments.Appointment {...restProps } resources={[]} data={data} draggable={false}>
        <AppointmentComponentContent data={data} {...restProps} style={{}} />
      </Appointments.Appointment>
  );
};
