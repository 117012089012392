import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import {
  ClientGroupsPageState, closeClientGroupDialogAddEdit,
  fetchClientGroups,
  setInputDescription,
  setInputName,
} from '../clientGroupsPage.slice';
import clientGroupsService from '../../../services/clientGroups/clientGroups.service';
import React from 'react';
import validateForm from '../../../utils/validation/validateForm';

export const ClientGroupDialogAddEdit = () => {
  const dispatch: ThunkDispatch<ClientGroupsPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.clientGroups.dialogs.addEdit);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => dispatch(closeClientGroupDialogAddEdit({}));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, mode: 'add' | 'edit') => {
    if (!validateForm(e)) return;

    let textAction, textActionPast;

    try {
      if (!studioId) {
        throw new Error(`Studio not found`);
      }

      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        await clientGroupsService.add({
          name, description, studioId,
        });
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await clientGroupsService.edit(id as number, {
          name, description,
        });
      }

      dispatch(fetchClientGroups(studioId));
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} client group: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <form onSubmit={(e) => handleSubmit(e, mode)}>
        <DialogTitle>{mode === 'add' ? 'Add client group' : 'Edit client group'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => dispatch(setInputName(e.target.value))}
            sx={{ marginBottom: 1, marginTop: 1 }}
            required={true}
          />
          <TextField
            multiline={true}
            rows={6}
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) => dispatch(setInputDescription(e.target.value))}
            value={description}
            sx={{ marginTop: 1 }}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {mode === 'edit' ? 'Save Changes' : 'Add client group'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

