import { MatrixView, MatrixViewRaw } from '../../services/matrix/matrix.types';
import { dateToTimestring } from '../../utils/dateToTimestring';
import { dateToIsoString } from '../../utils/dateToIsoString';

export const matrixViewRawToMatrixView = (matrixView: MatrixViewRaw): MatrixView => {
  return {
    ...matrixView,
    startDate: dateToIsoString(matrixView.startDate),
    endDate: dateToTimestring(matrixView.startDate),
  }
};
