import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import React from 'react';
import { DateTime } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

import {
  closeStudioDialogAddEdit,
  fetchStudios,
  setImage,
  setInputAddress,
  setInputDescription,
  setInputName, setInputRules,
  setInputTimezone, setInputWorkTimeEnd, setInputWorkTimeStart,
  setInputCancellationDeadline,
  StudiosPageState
} from '../studiosPage.slice';
import { RootState } from '../../../store/store';
import { toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import StudiosService from '../../../services/studios/studios.service';
import UploadImage from '../../../components/UploadImage';
import { dataURItoBlob } from '../../../utils/dataUriToBlob';
import validateForm from '../../../utils/validation/validateForm';

export const StudioDialogAddEdit = () => {
  const dispatch: ThunkDispatch<StudiosPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id, timezone, address, rules, workTimeStart, workTimeEnd, photo, cancellationDeadline },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.studios.dialogs.addEdit);
  const { data: studios } = useSelector((state: RootState) => state.studios);
  const timezones = useSelector((state: RootState) => state.dictionaries.timezones);

  const dialogClose = () => { dispatch(closeStudioDialogAddEdit({})) };

  const removeImage = () => { dispatch(setImage('')) };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, mode: 'add' | 'edit') => {
    if (!validateForm(e)) return;

    let textAction, textActionPast;
    const body = { name, description, rules, address, workTimeStart, workTimeEnd, cancellationDeadline } as {
      name: string;
      cancellationDeadline: number;
      description: string;
      address: string;
      rules: string;
      timezone: string;
      workTimeStart: string;
      workTimeEnd: string;
    };

    try {
      let studioId = id || null;
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        body.timezone = timezone;

        const newStudio = await StudiosService.add(body);
        studioId = newStudio.id;
      } else {
        [textAction, textActionPast] = ['edit', 'edited'];

        await StudiosService.edit(id as number, body);
      }

      if (photo?.includes('data:image')) {
        const file = dataURItoBlob(photo);
        await StudiosService.uploadImage(studioId as number, file);
      } else if (!photo) {
        const currentStudio = studios.find((studio) => studio.id === id);
        if (currentStudio && currentStudio.photo) {
          await StudiosService.deleteImage(id as number);
        }
      }

      dispatch(fetchStudios());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} studio: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",  // Set your width here
        },
      },
    }}>
      <form onSubmit={(e) => handleSubmit(e, mode)}>
        <DialogTitle>{mode === 'add' ? 'Add studio' : 'Edit studio'}</DialogTitle>
        <DialogContent>
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '400px'}}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => dispatch(setInputName(e.target.value))}
                sx={{marginTop: '20px'}}
                required={true}
              />
              <TextField
                multiline={true}
                rows={6}
                label="Description"
                variant="outlined"
                fullWidth
                onChange={(e) => dispatch(setInputDescription(e.target.value))}
                value={description}
                sx={{marginTop: '20px'}}
                required={true}
              />
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                onChange={(e) => dispatch(setInputAddress(e.target.value))}
                value={address}
                sx={{marginTop: '20px'}}
                required={true}
              />
              <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"ru-RU"}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <TimePicker
                    label="Work start"

                    value={DateTime.fromISO(workTimeStart).toJSDate()}
                    // slotProps={{ textField: { size: "small", error: false } }}
                    onChange={(dateTime: DateTime | null, _keyboardInputValue?: string) => {
                      if (dateTime) {
                        dispatch(setInputWorkTimeStart(dateTime.toFormat('HH:mm')));
                      } else {
                        dispatch(setInputWorkTimeStart(''));
                      }
                    }}
                    renderInput={(props) => <TextField {...props} error={false} required={true} sx={{
                      marginTop: '20px',
                      marginRight: '10px',
                      width: '50%'

                    }}/>}
                  />
                  <TimePicker
                    label="Work end"
                    value={DateTime.fromISO(workTimeEnd).toJSDate()}
                    onChange={(dateTime: DateTime | null, _keyboardInputValue?: string) => {
                      if (dateTime) {
                        dispatch(setInputWorkTimeEnd(dateTime.toFormat('HH:mm')));
                      } else {
                        dispatch(setInputWorkTimeEnd(''));
                      }
                    }}
                    renderInput={(props) => <TextField {...props} error={false} required={true} sx={{
                      marginTop: '20px',
                      width: '50%'
                    }}/>}
                  />
                </Box>
              </LocalizationProvider>
              <TextField
                label="Cancellation deadline"
                variant="outlined"
                fullWidth
                onChange={(e) => dispatch(setInputCancellationDeadline(e.target.value))}
                value={cancellationDeadline}
                sx={{marginTop: '20px'}}
                type={'number'}
                InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}
                inputProps={{min: 0}}
              />
            </Box>
            <Box sx={{width: '400px', marginLeft: '20px'}}>
              <TextField
                multiline={true}
                rows={6}
                label="Rules"
                variant="outlined"
                fullWidth
                onChange={(e) => dispatch(setInputRules(e.target.value))}
                value={rules}
                sx={{marginTop: '20px'}}
                required={true}
              />
              <Autocomplete
                options={timezones.map((timezone) => ({
                  id: timezone,
                  label: timezone,
                }))}
                fullWidth
                disabled={mode !== 'add'}
                inputValue={timezone}
                onChange={(_, newInputValue) => dispatch(setInputTimezone(newInputValue?.label || ''))}
                sx={{marginBottom: 1, marginTop: 1}}
                renderInput={(params) => <TextField
                  {...params}
                  label="Timezone"
                  variant="outlined"
                  required={true}
                  sx={{
                    marginTop: '10px'
                  }}
                />}
              />

              <UploadImage image={photo} setImage={setImage} removeImage={removeImage}/>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">{mode === 'edit' ? 'Save Changes' : 'Add studio'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

