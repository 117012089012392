import { Box } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { DialogDelete } from './DialogDelete';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadImage = ({ image, setImage, removeImage }: { image: string, setImage: any, removeImage: () => void }) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      console.error('no files');
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function() {
      dispatch(setImage(reader.result as string));
      // Очищаем значение input, чтобы разрешить повторную загрузку того же изображения
      event.target.value = '';
    };
  };

  const handleImageRemove = () => {
    if (image.includes('data:image')) {
      removeImage();
    } else {
      setDeleteDialogOpen(true);
    }
  }

  return (
    <>
      <div style={{
        boxSizing: 'border-box',
        width: '100%',
        margin: 'auto',
        border: '1px solid grey',
        borderRadius: '5px',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
      }}>
        <img src={(image !== '' && image) || '/logo96.png'} style={{ maxWidth: '400px', maxHeight: '200px' }} />
        <br/>
        <Box component="label">
          <span className={'link'}>Upload photo</span>
          <VisuallyHiddenInput type={'file'} onChange={(e) => handleImageUpload(e)} />
        </Box>
        {image && image !== '' && (
          <>
            &nbsp;|&nbsp;
            <span className={'link'} onClick={handleImageRemove}>Remove photo</span>
          </>
        )}
      </div>

      <DialogDelete
        open={isDeleteDialogOpen}
        question="Are you sure you want to delete this image?"
        onClose={() => setDeleteDialogOpen(false)}
        onSubmit={() => { setDeleteDialogOpen(false); void removeImage(); }}
      />
    </>
  );
}

export default UploadImage;
