import { ServiceCreateDTO, ServiceDTO } from './services.types';
import BaseService from '../base/base.service';
import { ApiService } from '../api/api.service';

class ServicesService extends BaseService<ServiceDTO, ServiceCreateDTO> {
  readonly url = 'services';

  public async uploadImage(id: number, file: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('photo', file);

    const response = await ApiService.upload(`services/${id}/photo`, formData);
    return response.data;
  }

  public async deleteImage(id: number): Promise<void> {
    await ApiService.delete(`services/${id}/photo`);
  }
}

const servicesService = new ServicesService();

export default servicesService;
