import React from 'react';

const validateForm = (e: React.FormEvent<HTMLFormElement>): boolean => {
  e.preventDefault();

  const formElement = e.target as HTMLFormElement;
  const isValid = formElement.checkValidity();

  const firstInvalidField = formElement.querySelector(':invalid') as HTMLInputElement;
  firstInvalidField?.focus();

  return isValid;
};

export default validateForm;
