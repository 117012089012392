import { AuthLoginResponse } from './auth.types';
import { ApiService } from '../api/api.service';

class AuthService {
  public static async register(username: string, email: string, password: string) {
    return ApiService.post("signup", {
      username,
      email,
      password,
    });
  }

  public static async login(email: string, password: string): Promise<AuthLoginResponse> {
    let response: any;

    try {
      response = await ApiService.post('auth/login', { email, password });
      if (response.data.accessToken) {
        localStorage.setItem('user', response.data.accessToken);
      }

      return {
        success: true,
      };
    } catch(e: any) {
      return {
        success: false,
        error: e.message || 'Internal error',
      };
    }
  }

  public static logout() {
    localStorage.removeItem('user');
  }

  public static getCurrentUser() {
    return localStorage.getItem("user");
  }

  public static checkAuth(): boolean {
    const userToken = AuthService.getCurrentUser();

    return !!userToken;
  }

  public static async sendRecoverCode(email: string): Promise<void> {
    await ApiService.post('profile/password/reset/request-code', {
      email,
    });
  }

  public static async confirmForgotPassword(email: string, code: string, password: string): Promise<void> {
    await ApiService.put('profile/password/reset/confirm', {
      code,
      email,
      password,
    });
  }
}

export default AuthService;
