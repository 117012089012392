import { Button, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { loadClientData, toggleDialogSubscriptionAdd } from '../clientCardPage.slice';
import CustomNoRowsOverlay from '../../../components/grid/CustomNoRowsOverlay';
import { SubscriptionAddDialog } from './SubscriptionAddDialog';
import { Subscription, SubscriptionWithClient } from '../../subscriptions/subscriptions.types';
import currencySign from '../../../utils/currencySign';
import { getServiceGroupsWithDeleted } from '../../../store/dictionaries.selectors';
import { RootState } from '../../../store/store';
import ReplyIcon from '@mui/icons-material/Reply';
import SubscriptionDialogRefund from '../../../components/subscriptions/SubscriptionDialogRefund';
import EditIcon from '@mui/icons-material/Edit';
import SubscriptionEditDialog from './SubscriptionEditDialog';

const SubscriptionsGrid = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const serviceGroupsWithDeleted = useSelector(getServiceGroupsWithDeleted);
  const { subscriptions, surname, name, clientId, phone, email } = useSelector((state: RootState) => state.clientCard);
  const [refundSubscription, setRefundSubscription] = useState<SubscriptionWithClient>();
  const [editSubscription, setEditSubscription] = useState({
    client: { }
  });
  const [isRefundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  const openSubscriptionDialogRefund = (subscription: Subscription) => {
    setRefundSubscription({
      ...subscription,
      client: {
        id: Number(clientId),
        phone,
        email,
        name,
        surname,
      },
    });
    setRefundDialogOpen(true);
  };

  const openSubscriptionDialogEdit = (subscription: Subscription) => {
    setEditSubscription({
      ...subscription,
      client: {
        id: clientId,
        phone,
        email,
        name,
        surname,
      },
    });
    setEditDialogOpen(true);
  };

  const columnsSubscriptions: GridColDef<Subscription>[] = [{
    field: 'id',
  },{
    field: 'serviceGroupId',
    headerName: 'Service group',
    flex: 1,
    renderCell: (r) => serviceGroupsWithDeleted.find((sg) => sg.id === r.row.serviceGroupId)?.name ?? ''
  },{
    field: 'period',
    headerName: 'Period',
    width: 200,
    renderCell: (r) => {
      const start = DateTime.fromISO(r.row.dateStart).toFormat('dd.MM.yyyy');
      const end = r.row.dateEnd ? DateTime.fromISO(r.row.dateEnd).toFormat('dd.MM.yyyy') : 'unlimited';
      return `${start} - ${end}`;
    },
  },{
    field: 'limits',
    headerName: 'Limits',
    width: 200,
    renderCell: (r) => r.row.limit + ' / ' + r.row.totalVisits,
  },{
    field: 'price',
    headerName: 'Price',
    width: 200,
    renderCell: (r) => r.row.price + ' ' + currencySign(r.row.currency),
  }, {
    field: 'edit',
    headerName: 'Edit',
    width: 60,
    sortable: false,
    renderCell: (params) => (
      <IconButton onClick={ () => openSubscriptionDialogEdit(params.row) }>
        <EditIcon />
      </IconButton>
    ),
  },{
    field: 'refund',
    headerName: 'Refund',
    width: 60,
    sortable: false,
    renderCell: (params) => {
      if (params.row.totalVisits - params.row.limit === 0) {
        return (
          <IconButton onClick={ () => openSubscriptionDialogRefund(params.row) }>
            <ReplyIcon />
          </IconButton>
        );
      }
    }
  }];

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: 1 }}
        onClick={() => dispatch(toggleDialogSubscriptionAdd(true))}
        startIcon={<AddCircleIcon/>}
      >Add subscription</Button>

      <SubscriptionAddDialog/>
      <SubscriptionDialogRefund
        isOpen={isRefundDialogOpen}
        setOpened={setRefundDialogOpen}
        refundSubscription={refundSubscription as SubscriptionWithClient}
        callback={() => dispatch(loadClientData(clientId as number)) }
      />

      <SubscriptionEditDialog
        isOpen={isEditDialogOpen}
        setOpened={setEditDialogOpen}
        subscription={editSubscription as SubscriptionWithClient}
        callback={() => dispatch(loadClientData(clientId as number)) }
      />

      <DataGrid
        sx={{
          '&, [class^=MuiDataGrid-root]': { border: 'none' },
          height: 'calc(100vh - 420px)',
          width: '100%'
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        slots={{ noRowsOverlay: () => <CustomNoRowsOverlay text={"No subscriptions"} /> }}
        columns={columnsSubscriptions}
        rows={subscriptions}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </>
  );
}

export default SubscriptionsGrid;
