import { Box, Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import React from 'react';
import { setInputMessage, setInputSubject, setValidateMode } from './supportPage.slice';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import SupportService from '../../services/support/support.service';
import validateForm from '../../utils/validation/validateForm';

const MySettingsPage = () => {
  const dispatch = useDispatch();

  const {
    subject, message, subjectInitial, messageInitial,
  } = useSelector((state: RootState) => state.support);

  const sendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;

    dispatch(setValidateMode(true));

    const result = await SupportService.sendMessage({
      subject, message,
    });

    if (result === true) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully sent.`,
      }));

      dispatch(setInputSubject(''));
      dispatch(setInputMessage(''));
      dispatch(setValidateMode(false));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } else {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: result.error,
      }));
    }
  };

  return (
    <Box width={600} component="form" onSubmit={sendMessage}>
      <h2>Send us message</h2>

      <TextField
        label="Subject"
        required={true}
        variant="outlined"
        fullWidth
        value={subject}
        error={!subjectInitial && subject.length === 0}
        onChange={(e) => dispatch(setInputSubject(e.target.value))}
        sx={{ marginBottom: 1, marginTop: 1 }}
      />
      <TextField
        label="Message"
        multiline={true}
        required={true}
        rows={8}
        variant="outlined"
        fullWidth
        onChange={(e) => dispatch(setInputMessage(e.target.value))}
        error={!messageInitial && message.length === 0}
        value={message}
        sx={{ marginTop: 1 }}
      />

      <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 1 }}>Send message</Button>
    </Box>
  );
};

export default MySettingsPage;
