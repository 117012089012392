import { Box, Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { AnyAction } from 'redux';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchIcon from '@mui/icons-material/Search';

import {
  closeDialogBookingAdd,
  loadClient,
  loadSubscriptions, resetClient, setClientVisibility,
  setInputClient,
  setSubscriptionId,
} from './bookingAddDialog.slice';
import { RootState } from '../../store/store';
import clientPhoto from '../../assets/images/client.png';
import ClientsService from '../../services/clients/clients.service';
import { isNumeric } from '../../utils/isNumeric';
import { openError, toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { getServicesWithDeleted } from '../../store/dictionaries.selectors';
import { Specialist } from '../../services/specialists/specialists.types';
import { Service } from '../../services/services/services.types';
import { Subscription } from '../subscriptions/subscriptions.types';
import bookingsService from '../../services/bookings/bookings.service';
import { fetchAppointment } from '../schedule/schedulePage.slice';
import { InitialValues } from '../../types/types';
import { Participant } from '../schedule/schedulePage.types';
import validateForm from '../../utils/validation/validateForm';

export const BookingAddDialog = (props: { successCallback: () => void, otherParticipants: Participant[] }) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const {
    openDialog,
    client,
    subscriptions,
    subscriptionId,
    clientVisibility,
    clientCard: {
      id: clientId,
      email,
      name,
      surname,
      phoneNumber: phone,
      // photo,
      instagram,
      telegram,
      whatsapp,
    },
    lesson: {
      scheduleId,
      serviceId,
      specialistId,
      startDate,
      endDate,
    },
  } = useSelector((state: RootState) => state.bookingAdd);
  const services = useSelector(getServicesWithDeleted);
  const { specialists } = useSelector((state: RootState) => state.dictionaries);
  const { roomId: _roomId } = useSelector((state: RootState) => state.schedule);
  const { studioId } = useSelector((state: RootState) => state.app);

  const [roomId] = [Number(_roomId)];

  const dialogClose = () => {
    dispatch(closeDialogBookingAdd({}));
    setTimeout(() => dispatch(resetClient({})), 200);
  }

  const clientSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;
    if (!studioId) { dispatch(openError(`Studio not found`)); return; }

    const filter = isNumeric(client)
      ? [{ id: Number(client) }]
      : [{ email: client }, { phoneNumber: client }];

    try {
      const { elements } = await ClientsService.getList({
        studioId,
        filter: btoa(JSON.stringify(filter)),
      });

      if (!elements[0]) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Client not found',
        }));
        dispatch(setClientVisibility(false));
        return;
      }

      if (props.otherParticipants.map(participant => participant.participantId).includes(elements[0].id)) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Client already booked.',
        }));
        dispatch(setClientVisibility(false));
        return;
      }

      dispatch(loadClient(elements[0]));
      dispatch(setClientVisibility(true));
      dispatch(loadSubscriptions({
        scheduleId,
        studioId,
        clientId: elements[0].id,
      }));
    } catch(err: unknown) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while search client',
      }));
      return;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;
    if (!clientId || !specialistId || !subscriptionId || !serviceId) { dispatch(openError(`Invalid data`)); return; }

    try {
      await bookingsService.create({
        clientId,
        roomId,
        specialistId,
        subscriptionId,
        serviceId,
        scheduleId,
        start: DateTime.fromISO(startDate).toFormat('HH:mm'),
        end: DateTime.fromISO(endDate).toFormat('HH:mm'),
        date: DateTime.fromISO(startDate).toFormat('yyyy-MM-dd'),
      });

      dispatch(fetchAppointment({ id: scheduleId }));

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: 'Booking successfully created.',
      }));
      props.successCallback();
      dialogClose();
    } catch(err: unknown) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: 'Error while creating booking',
      }));
    }
  };

  return (
    <Dialog open={openDialog} onClose={dialogClose} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",  // Set your width here
        },
      },
    }}>

      <DialogTitle>Add booking</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={clientSearch}
          sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px'}}
        >
          <TextField
            label="Client (id, phone or email)"
            variant="outlined"
            fullWidth
            value={client}
            onChange={(e: any) => dispatch(setInputClient(e.target.value))}
            required={true}
            InputProps={{
              style: {
                borderRadius: '15px',
              }
            }}
            inputProps={{
              style: {
                padding: '12px 15px'
              }
            }}
            sx={{
              margin: '5px 0px'
            }}
          />
          <Button type="submit" startIcon={<SearchIcon/>} variant='contained' sx={{marginLeft: '10px', padding: '11px 30px'}}>
            Search&nbsp;client
          </Button>
        </Box>

        <Box sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
          border: '2px solid #D9D9D9',
          borderRadius: '16px',
          padding: '20px'
        }}>
          Service: {services.find((service: Service) => service.id === serviceId)?.name || 'not found'}
          <br/>
          Specialist: {specialists[Number(studioId)]?.find((specialist: Specialist) => specialist.id === specialistId)?.name || 'not found'}
          <br/>
          Date and time: {DateTime.fromISO(startDate).toFormat('dd.MM.yyyy HH:mm')} - {DateTime.fromISO(endDate).toFormat('HH:mm')}
        </Box>

        {clientVisibility &&
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
              border: '2px solid #D9D9D9',
              borderRadius: '16px',
              padding: '20px'
            }}>
              <Box sx={{height: '150px', marginRight: '10px'}}>
                  <img src={clientPhoto} height={'150px'}/>
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', width: '100%'
              }}>
                <Box sx={{width: '100%', lineHeight: '1.5em'}}>
                    <div className={'client-card-row'}>
                        <NewspaperIcon/> {name} {surname}
                    </div>
                    <div className={'client-card-row'}>
                        <LocalPhoneIcon/> {phone}
                    </div>
                    <div className={'client-card-row'}>
                        <EmailIcon/> {email}
                    </div>
                  {!!telegram && (
                      <div className={'client-card-row'}>
                        <TelegramIcon/> {telegram}
                      </div>
                  )}
                  {!!whatsapp && (
                      <div className={'client-card-row'}>
                        <WhatsAppIcon/> {whatsapp}
                      </div>
                  )}
                  {!!instagram && (
                      <div className={'client-card-row'}>
                        <InstagramIcon/>
                        <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
                          @{instagram}
                        </a>
                      </div>
                  )}
                </Box>
                <Box sx={{marginLeft: 'auto'}}>
                    ID:&nbsp;{clientId}
                </Box>
              </Box>
            </Box>
            <Box sx={{
              display: 'block', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
              border: '2px solid #D9D9D9',
              borderRadius: '16px',
              padding: '20px'
            }}>
              <Box sx={{display: 'flex'}}>
                <Box sx={{marginTop: '18px'}}>Select subscription:</Box>
                <Select
                    placeholder={"Subscription"}
                    value={subscriptionId}
                    onChange={(e) => dispatch(setSubscriptionId(e.target.value))}
                    disabled={subscriptionId === InitialValues.NO_OPTIONS}
                    required={true}
                    sx={{
                      backgroundColor: 'white',
                      minWidth: '400px',
                      marginLeft: '20px',
                      borderRadius: '15px',
                      "& .MuiSelect-icon": {
                        fontSize: '200%',
                      },
                    }}
                >
                  {subscriptions.length === 0 ? (
                      <MenuItem value={InitialValues.NO_OPTIONS}>No subscriptions</MenuItem>
                  ) : (
                      subscriptions.map((subscription: Subscription) => (
                          <MenuItem key={subscription.id} value={subscription.id}>
                            {DateTime.fromISO(subscription.dateStart).toFormat('dd.MM.yyyy')}
                            -
                            {subscription.dateEnd ? DateTime.fromISO(subscription.dateEnd).toFormat('dd.MM.yyyy') : 'unlimited'}
                            &nbsp;//&nbsp;
                            free {subscription.limit}
                          </MenuItem>
                      ))
                  )}
                </Select>
              </Box>
            </Box>

            <Box width="100%" justifyContent="flex-end" display="flex">
              <Button onClick={dialogClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">Add booking</Button>
            </Box>
          </form>
        }
      </DialogContent>
    </Dialog>
  );
};

